import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import Image from './Image'
import { Link } from 'gatsby'

import './SponsorLogo.css'

class SponsorLogo extends Component {
  constructor(props) {
    super(props)
  }
  sponsorlogoWidth(logo) {
    switch (logo.title) {
      case 'ゴールドウィンモーターサイクル':
        return 'super-wide';
      case '株式会社C4PLANNING':
      case '株式会社スターズトレーディング':
        return 'main-wide';
      case 'Beta motorcycles':
        return '';
      case 'バイクマン':
        return 'logo-wide';
      default:
        return 'logo-wide-small';
    }
  }

  render() {
    const { sponsorLogo } = this.props
    return (
      <section>
        <ul className="BackgroundVideo-Under-logosContainer">
          {!!sponsorLogo &&
            sponsorLogo.filter((logo) => logo.title === "バイクマン" || logo.title === "Beta motorcycles").map((logo, index) => (
              <li
                className={this.sponsorlogoWidth(logo)}
              >
                <a href={logo.url} target="_blank" rel="noopener noreferrer">
                  <Image
                    resolutions="small"
                    src={logo.image.url}
                    alt={logo.alt}
                    title={logo.title}
                    className="BackgroundVideo--sponsorLogo"
                  />
                </a>
              </li>
            ))}
        </ul>
        <ul className="BackgroundVideo-Under-logosContainer">
          {!!sponsorLogo &&
            sponsorLogo.filter((logo) => logo.title !== "バイクマン" && logo.title !== "Beta motorcycles").map((logo, index) => (
              <li
                className={this.sponsorlogoWidth(logo)}
              >
                <a href={logo.url} target="_blank" rel="noopener noreferrer">
                  <Image
                    resolutions="small"
                    src={logo.image.url}
                    alt={logo.alt}
                    title={logo.title}
                    className="BackgroundVideo--sponsorLogo"
                  />
                </a>
              </li>
            ))}
        </ul>
      </section>
    )
  }
}

export default SponsorLogo
