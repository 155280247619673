import React, { Fragment } from 'react'
import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import { ChevronLeft, ChevronUp, Edit  } from 'react-feather'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon
} from 'react-share'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './SinglePost.css'
import Image from '../components/Image'
import { formatToTimeZone } from 'date-fns-timezone'
import scrollTo from 'gatsby-plugin-smoothscroll'
import LogoR from '../components/LogoR'
require('date-utils')


const FORMAT = 'YYYY-MM-DD HH:mm'
const TIME_ZONE_TOKYO = 'Asia/Tokyo'

export const SinglePostTemplate = ({
  title,
  updatedAt,
  createdAt,
  body,
  nextPostURL,
  prevPostURL,
  url,
  category = [],
  excerpt,
  applySiteURL,
  sponsorLogo,
  toc_visible
}) => (
  <main>
    <article
      className="SinglePost section light"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <div className="container skinny">
        <Link className="SinglePost--BackButton" to="/post-categories/index">
          <ChevronLeft /> BACK
        </Link>
        <div className="SinglePost--Content relative">
          <div className="SinglePost--Meta">
            {updatedAt && (
              <time
                className="SinglePost--Meta--Date"
                itemProp="dateCreated pubdate datePublished"
                date={updatedAt}
              >
                {updatedAt}
              </time>
            )}
            {updatedAt === createdAt ? '作成' : '更新'}
            {category && (
              <Fragment>
                <span>|</span>
                {category.map((cat, index) => (
                  <span key={cat} className="SinglePost--Meta--Category">
                    {cat}
                    {/* Add a comma on all but last category */}
                    {index !== category.length - 1 ? ',' : ''}
                  </span>
                ))}
              </Fragment>
            )}
          </div>

          {title && (
            <h1 className="SinglePost--Title" itemProp="title">
              {title}
            </h1>
          )}

          <div className="SinglePost--InnerContent">
            <Content source={body} toc={toc_visible} />
            <ul className="SinglePost--logosContainer">
              {sponsorLogo.length !== 0 &&
                sponsorLogo.map((logo, index) => (
                  <li
                    className={`${
                      logo.title !== '株式会社アイ・ケイ・アイ' &&
                      logo.title !== 'Beta motorcycles' &&
                      logo.title !== 'フレアライン'
                        ? 'logo-wide'
                        : ''
                    }`}
                  >
                    <Link to={logo.url}>
                      <Image
                        resolutions="small"
                        src={logo.image.url}
                        alt={logo.alt}
                        title={logo.title}
                        className="SinglePost--sponsorLogo"
                      />
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          <div className="SinglePost--Button">
            <FacebookShareButton url={url} quote={excerpt}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton title={title} via="ren_x_mission" url={url}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <LineShareButton url={url} quote={excerpt}>
              <LineIcon round size={32} round />
            </LineShareButton>
          </div>
          <div className="SinglePost--Button" style={{ display: "flex" }}>
            <LogoR />
            <p>公式LINEアカウントは<a href="https://lin.ee/3qe595b">こちら</a></p>
          </div>
          <div className="SinglePost--Pagination">
            {prevPostURL && (
              <Link
                className="SinglePost--Pagination--Link prev"
                to={prevPostURL}
              >
                Previous Post
              </Link>
            )}
            {nextPostURL && (
              <Link
                className="SinglePost--Pagination--Link next"
                to={nextPostURL}
              >
                Next Post
              </Link>
            )}
          </div>
        </div>
      </div>
      {applySiteURL && (
        <div class="button_link">
          <Link to={applySiteURL}>
              <Edit size={40} style={{paddingBottom: '0.5em'}}/><span>申込FORM</span>
          </Link>
        </div>
      )}
      <div class="button_up">
        <p onClick={() => scrollTo('body')}><ChevronUp size={40} />PAGE TOP</p>
      </div>
    </article>
  </main>
)

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts } }) => {
  const thisEdge = allPosts.edges.find((edge) => edge.node.id === post.id)
  return (
    <Layout meta={post.meta || false} title={post.title || false}>
      <SinglePostTemplate
        {...post}
        body={post.content}
        updatedAt={formatToTimeZone(new Date(post.updatedAt), FORMAT, {
          timeZone: TIME_ZONE_TOKYO
        })}
        createdAt={formatToTimeZone(new Date(post.createdAt), FORMAT, {
          timeZone: TIME_ZONE_TOKYO
        })}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
        url={typeof window !== 'undefined' ? window.location.href : ''}
        sponsorLogo={post.sponsorLogo}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: microcmsPosts(id: { eq: $id }) {
      content
      id
      title
      excerpt
      updatedAt
      createdAt
      category
      applySiteURL
      sponsorLogo {
        image {
          url
        }
        url
        title
        alt
      }
      meta {
        title
        description
      }
      toc_visible
    }

    allPosts: allMicrocmsPosts(sort: { order: DESC, fields: [updatedAt] }) {
      edges {
        node {
          id
          fields {
            slug
          }
        }
        next {
          fields {
            slug
          }
          title
        }
        previous {
          fields {
            slug
          }
          title
        }
      }
    }

    sponsorLogos: allMicrocmsSponsorLogo {
      edges {
        node {
          image {
            url
          }
          url
          title
          alt
        }
      }
    }
  }
`
