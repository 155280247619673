import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faYoutube,
  faLine
} from '@fortawesome/free-brands-svg-icons'

import './Footer.css'

export default () => (
  <div>
    {/* <h2 className="taCenter">
      Follow us{' '}
      <a href="https://instagram.com/thrivegoldcoast/">@thrivegoldcoast</a>
    </h2> */}
    {/* <br /> */}
    {/* <InstagramFeed count="8" /> */}
    <footer className="footer">
      <div className="container taCenter Footer--Container">
        <div class="pc">
          © Copyright 2020-{new Date().getFullYear()} Ren Ishidoya & CROSS MISSION
          All rights reserved. &nbsp;&nbsp;|&nbsp;&nbsp;Crafted by&nbsp;
          <a href="https://c4planning.co.jp">C4PLANNING Inc.</a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/privacy-policy/">プライバシーポリシー</a>
        </div>

        <div class="Footer--SNS sp">
          <a
            href={`https://ja-jp.facebook.com/ishidoya.ren`}
            style={{ boxShadow: `none` }}
          >
            <FontAwesomeIcon
              color="#3b5998"
              icon={faFacebookSquare}
              style={{
                width: `24px`,
                height: `24px`,
                marginRight: `4px`
              }}
            />
          </a>
          <a
            href={`https://twitter.com/ren_x_mission`}
            style={{ boxShadow: `none` }}
          >
            <FontAwesomeIcon
              color="#3eaded"
              icon={faTwitterSquare}
              style={{
                width: `24px`,
                height: `24px`,
                marginRight: `4px`
              }}
            />
          </a>
          <a
            href={`https://www.instagram.com/cross.mission/`}
            style={{ boxShadow: `none` }}
          >
            <FontAwesomeIcon
              icon={faInstagram}
              color="#000"
              style={{
                width: `24px`,
                height: `24px`,
                marginRight: `4px`
              }}
            />
          </a>
          <a
            href={`https://www.instagram.com/ishidoya_ren/`}
            style={{ boxShadow: `none` }}
          >
            <FontAwesomeIcon
              icon={faInstagram}
              color="#3AE09A"
              style={{
                width: `24px`,
                height: `24px`,
                marginRight: `4px`
              }}
            />
          </a>
          <a
            href={`https://www.youtube.com/channel/UCpXVNW-941B6Ewpfu9AkR_w`}
            style={{ boxShadow: `none` }}
          >
            <FontAwesomeIcon
              color="#FF0000"
              icon={faYoutube}
              style={{
                width: `30px`,
                height: `24px`,
                marginRight: `4px`
              }}
            />
          </a>
          <a
            href={`https://lin.ee/3qe595b`}
            style={{ boxShadow: `none` }}
          >
            <FontAwesomeIcon
              color="#00B900"
              icon={faLine}
              style={{
                width: `30px`,
                height: `24px`,
                marginRight: `4px`
              }}
            />
          </a>
        </div>
        <div class="sp">
          <span>
            © Copyright 2020- {new Date().getFullYear()} Ren Ishidoya & CROSS MISSION
            All rights reserved.
          </span>

          <span>
            Crafted by&nbsp;
            <a href="https://c4planning.co.jp">C4PLANNING Inc.</a>
          </span>

          <span>
            <a href="/privacy-policy/">プライバシーポリシー</a>
          </span>
        </div>
      </div>
    </footer>
  </div>
)
