import React from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import { ChevronUp } from 'react-feather'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon
} from 'react-share'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './SinglePost.css'
import scrollTo from 'gatsby-plugin-smoothscroll'
import LogoR from '../components/LogoR'
import PageHeader from '../components/PageHeader'
import './InsurancePage.css'

// Export Template for use in CMS preview
export const InsurancePageTemplate = ({
  title,
  subTitle,
  featuredImage,
  body,
  url,
  excerpt,
  toc_visible
}) => (
  <main className="InsurancePage">
    <PageHeader
      title={title}
      subtitle={subTitle}
      backgroundImage={featuredImage.url}
      className="PageHeader-large"
    />

    <section className="section">
      <div className="container">
        <Content source={body} toc={toc_visible} />
        <div className="SinglePost--Button">
          <FacebookShareButton url={url} quote={excerpt}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton title={title} via="ren_x_mission" url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <LineShareButton url={url} quote={excerpt}>
            <LineIcon round size={32} round />
          </LineShareButton>
        </div>
        <div className="SinglePost--Button" style={{ display: 'flex' }}>
          <LogoR />
          <p>
            公式LINEアカウントは
            <a href="https://lin.ee/3qe595b">こちら</a>
          </p>
        </div>
      </div>
      <div class="button_up">
        <p onClick={() => scrollTo('body')}>
          <ChevronUp size={40} />
          PAGE TOP
        </p>
      </div>
    </section>
  </main>
)

const InsurancePage = ({ data: { page } }) => (
  <Layout meta={page.meta || false} title={page.meta.title || false}>
    <InsurancePageTemplate
      {...page}
      url={typeof window !== 'undefined' ? window.location.href : ''}
    />
  </Layout>
)
export default InsurancePage

export const pageQuery = graphql`
  query InsurancePage($id: String!) {
    page: microcmsInsurancePage(id: { eq: $id }) {
      meta {
        title
        description
      }
      title
      subTitle
      featuredImage {
        url
      }
      excerpt
      body
      toc_visible
    }
  }
`
