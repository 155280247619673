import React from 'react'
import './GallerySection.css'
import Image from '../components/Image'

class GallerySection extends React.Component {
  static defaultProps = {
    images: [],
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () =>
    this.setState((prevState) => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    const { images, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      visibleImages = images.slice(0, limit || images.length)
    return (
      <div className="GallerySection">
        {!!visibleImages.length && (
          <div className="GallerySection--Grid">
            {visibleImages.map((image, index) => (
              <Image
                resolutions="small"
                className="Content--Image"
                src={image.image.url}
                alt={image.title}
                title={image.title}
              />
              //   <PostCard key={post.title + index} {...post} />
            ))}
          </div>
        )}
        {showLoadMore && visibleImages.length < images.length && (
          <div className="taCenter">
            <button className="button" onClick={this.increaseLimit}>
              {loadMoreTitle}
            </button>
          </div>
        )}
      </div>
    )
  }
}

export default GallerySection
