import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import Recaptcha from 'react-google-recaptcha'
import Accordion from '../components/Accordion'

import './Form.css'

const RECAPTCHA_KEY = '6LeDpLkZAAAAANS-PdmYm2sJGl87cmLKbHAD-V9V'
const recaptchaRef = React.createRef() // 追加

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class Form extends React.Component {
  static defaultProps = {
    name: 'Simple Form Ajax',
    subject: 'お問い合わせ', // optional subject of the notification email
    action: 'thanks',
    successMessage:
      'お問い合わせありがとうございます。近日中に返信いたします。',
    errorMessage: '問題があります。メッセージが送信されていません。',
    recaptchaErrorMessage:
      '「私はロボットではありません」にチェックしてください。'
  }

  state = {
    alert: '',
    alertColor: 'red'
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const data = serialize(form)
    const recaptchaValue = recaptchaRef.current.getValue()
    if (recaptchaValue != null && recaptchaValue !== '') {
      fetch(form.action + '?' + stringify(data), {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          'g-recaptcha-response': recaptchaValue
        })
      })
        .then((res) => {
          if (res.ok) {
            return res
          } else {
            throw new Error('Network error')
          }
        })
        .then(() => {
          form.reset()
          this.setState({
            alert: this.props.successMessage,
            alertColor: 'black'
          })
        })
        .catch((err) => {
          console.error(err)
          this.setState({
            alert: this.props.errorMessage
          })
        })
    } else {
      this.setState({
        alert: this.props.recaptchaErrorMessage
      })
    }
  }

  render() {
    const { name, subject, action, accordion } = this.props

    return (
      <Fragment>
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Helmet>
        <form
          className="Form"
          name={name}
          action={action}
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-recaptcha="true"
        >
          <div className="Form--Group">
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Lastname"
                name="lastname"
                required
              />
              <span>姓</span>
            </label>
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Firstname"
                name="firstname"
                required
              />
              <span>名</span>
            </label>
          </div>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="email"
              placeholder="Email"
              name="emailAddress"
              required
            />
            <span>Email</span>
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="tel"
              placeholder="Tel"
              name="TelephoneNumber"
              required
            />
            <span>Tel</span>
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="Title"
              name="title"
              required
            />
            <span>件名</span>
          </label>
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Message"
              name="message"
              rows="10"
              required
            />
            <span>メッセージ</span>
          </label>
          <label className="Form--Label Form-Checkbox">
            <input
              className="Form--Input Form--Textarea Form--CheckboxInput"
              name="privacy-policy"
              type="checkbox"
              required
            />
            <span>プライバシーポリシーに同意する</span>
          </label>
          <Accordion items={accordion} />
          <Recaptcha
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            style={{ zIndex: 10000 }}
          />
          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input type="hidden" name="form-name" value={name} />
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="送信"
            style={{ zIndex: 10000 }}
          />
        </form>
        {this.state.alert && (
          <div className="Form--Alert">
            <span style={{ color: `${this.state.alertColor}` }}>
              {this.state.alert}
            </span>
          </div>
        )}
      </Fragment>
    )
  }
}

export default Form
