import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import PostSection from '../components/PostSection'
import PostCategoriesNav from '../components/PostCategoriesNav'
import Layout from '../components/Layout'
import Content from '../components/Content'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = (posts) => {
  const now = Date.now()
  return posts.filter((post) => Date.parse(post.updatedAt) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 */

// contentType=本文が空なら /post-categories
export const byCategory = (posts, title, contentType) => {
  const byCategory = (post) =>
    post.category && post.category.filter((cat) => cat === title).length
  return contentType ? posts.filter(byCategory) : posts
}

// Export Template for use in CMS preview
export const BlogIndexTemplate = ({
  title,
  subTitle,
  featuredImage,
  posts = [],
  postCategories = [],
  enableSearch = true,
  body
}) => (
  <Location>
    {({ location }) => {
      let filteredPosts =
        posts && !!posts.length ? byCategory(byDate(posts), title, body) : []

      let queryObj = location.search.replace('?', '')
      queryObj = qs.parse(queryObj)

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase()
        filteredPosts = filteredPosts.filter(
          (post) =>
            post.title.toLowerCase().includes(searchTerm) ||
            post.content.toLowerCase().includes(searchTerm) ||
            post.updatedAt.replace('-', '').includes(searchTerm)
        )
      }

      return (
        <main className="Blog">
          <PageHeader
            title={title}
            subtitle={subTitle}
            backgroundImage={featuredImage.url}
          />

          {!!postCategories.length && (
            <section className="section thin">
              <div className="container">
                <PostCategoriesNav enableSearch categories={postCategories} />
              </div>
            </section>
          )}

          {body && (
            <div className="container">
              <Content source={body} className="CategoryExcerpt" />
            </div>
          )}

          {!!posts.length && (
            <section className="section">
              <div className="container">
                <PostSection posts={filteredPosts} />
              </div>
            </section>
          )}
        </main>
      )
    }}
  </Location>
)

// Export Default BlogIndex for front-end
const BlogIndex = ({ data: { posts, postCategory, postCategories } }) => (
  <Layout meta={postCategory.meta || false} title={postCategory.title || false}>
    <BlogIndexTemplate
      {...postCategory}
      {...postCategory.featuredImage}
      body={postCategory.body}
      posts={posts.edges.map((post) => ({
        ...post.node,
        ...post.node.featuredImage,
        ...post.node.fields
      }))}
      postCategories={postCategories.edges.map((post) => ({
        ...post.node
      }))}
    />
  </Layout>
)

export default BlogIndex

export const pageQuery = graphql`
  ## Query for BlogIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query BlogIndex($id: String!) {
    postCategory: microcmsPostCategories(id: { eq: $id }) {
      slug
      title
      subTitle
      featuredImage {
        url
      }
      body
      meta {
        title
        description
      }
    }
    posts: allMicrocmsPosts(sort: { order: DESC, fields: [updatedAt] }) {
      edges {
        node {
          content
          excerpt
          title
          updatedAt
          createdAt
          category
          fields {
            slug
          }
          featuredImage {
            url
          }
          eventStatus
        }
      }
    }
    postCategories: allMicrocmsPostCategories {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`
