// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-home-jsx": () => import("./../../../src/pages/previewHome.jsx" /* webpackChunkName: "component---src-pages-preview-home-jsx" */),
  "component---src-pages-preview-page-jsx": () => import("./../../../src/pages/previewPage.jsx" /* webpackChunkName: "component---src-pages-preview-page-jsx" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/GalleryPage.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-insurance-page-js": () => import("./../../../src/templates/InsurancePage.js" /* webpackChunkName: "component---src-templates-insurance-page-js" */),
  "component---src-templates-ren-ishidoya-js": () => import("./../../../src/templates/RenIshidoya.js" /* webpackChunkName: "component---src-templates-ren-ishidoya-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

