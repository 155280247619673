import React from 'react'
// import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Content from '../components/Content'
// import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  title,
  subTitle,
  featuredImage,
  accordion

  // locations
}) => (
  <main className="Contact">
    <PageHeader
      title={title}
      subtitle={subTitle}
      backgroundImage={featuredImage.url}
    />
    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          <Content source={body} />
          {/* <div className="Contact--Details">
            {address && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.com.au/maps/search/${encodeURI(
                  address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin /> {address}
              </a>
            )}
            {phone && (
              <a className="Contact--Details--Item" href={`tel:${phone}`}>
                <Smartphone /> {phone}
              </a>
            )}
            {email && (
              <a className="Contact--Details--Item" href={`mailto:${email}`}>
                <Mail /> {email}
              </a>
            )}
          </div> */}
        </div>

        <div>
          <FormSimpleAjax name="Simple Form Ajax" accordion={accordion} />
        </div>
      </div>
    </section>

    {/* <GoogleMap locations={locations} /> */}
  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout meta={page.meta || false} title={page.title || false}>
    <ContactPageTemplate {...page} body={page.body} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: microcmsContact(id: { eq: $id }) {
      title
      template
      subTitle
      featuredImage {
        url
      }
      accordion {
        title
        description
      }
      body
      meta {
        title
        description
      }
    }
  }
`
