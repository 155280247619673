import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import Image from './Image'
import { Link } from 'gatsby'

import './BackgroundVideo.css'

class BackgroundVideo extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }
  state = {
    playing: false,
    mobileWidth: false
  }

  updateDimensions() {
    this.setState({ mobileWidth: window.innerWidth <= 767 })
  }

  handelPlay() {
    this.setState({ playing: true })
    ReactDOM.findDOMNode(this.ref.current).removeEventListener(
      'playing',
      this.handelPlay
    )
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', () => this.updateDimensions())
    ReactDOM.findDOMNode(this.ref.current).addEventListener('playing', (e) =>
      this.handelPlay(e)
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  sponsorlogoWidth(logo) {
    switch (logo.title) {
      case 'ゴールドウィンモーターサイクル':
        return 'super-wide';
      case '株式会社C4PLANNING':
      case '株式会社スターズトレーディング':
        return 'main-wide';
      case 'Beta motorcycles':
        return '';
      case 'バイクマン':
        return 'logo-wide';
      default:
        return 'logo-wide-small';
    }
  }

  render() {
    const { videoSponsorLogo, loadingImage } = this.props
    return (
      !this.state.mobileWidth ? (
        <Fragment>
          <div className={`BackgroundVideo`}>
            <video
              src="https://dl.dropboxusercontent.com/s/vi4bodl3jkedol3/Top-V1-high.mp4"
              ref={this.ref}
              poster={loadingImage.url}
              className={`BackgroundVideo--video ${
                this.state.playing ? 'playing' : ''
              } `}
              playsInline
              autoPlay
              muted
              loop
              preload="auto"
            ></video>
            <ul className="BackgroundVideo--logosContainer">
              {!!videoSponsorLogo &&
                videoSponsorLogo.filter((logo) => logo.title === "ゴールドウィンモーターサイクル" || logo.title === "株式会社C4PLANNING" || logo.title === "株式会社スターズトレーディング").map((logo, index) => (
                  <li
                    className={this.sponsorlogoWidth(logo)}
                  >
                    <a href={logo.url} target="_blank" rel="noopener noreferrer">
                      <Image
                        resolutions="small"
                        src={logo.image.url}
                        alt={logo.alt}
                        title={logo.title}
                        className="BackgroundVideo--sponsorLogo"
                      />
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={`BackgroundVideo`}>
            <video
                src="https://dl.dropboxusercontent.com/s/kibdfzt6arqdqfp/Top-V1-sp.mp4"
                ref={this.ref}
              poster={loadingImage.url}
              className={`BackgroundVideo--video ${
                this.state.playing ? 'playing' : ''
              } `}
              playsInline
              autoPlay
              muted
              loop
            ></video>
            <ul className="BackgroundVideo--logosContainer">
              {!!videoSponsorLogo &&
                videoSponsorLogo.filter((logo) => logo.title === "ゴールドウィンモーターサイクル" || logo.title === "株式会社C4PLANNING" || logo.title === "株式会社スターズトレーディング").map((logo, index) => (
                  <li
                    className={this.sponsorlogoWidth(logo)}
                  >
                    <Link to={logo.url}>
                      <Image
                        resolutions="small"
                        src={logo.image.url}
                        alt={logo.alt}
                        title={logo.title}
                        className="BackgroundVideo--sponsorLogo"
                      />
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </Fragment>
      )
    )
  }
}

export default BackgroundVideo
