import React, { useState, useEffect, Fragment } from 'react'
import { getSearchParams } from 'gatsby-query-params'
import { Link } from 'gatsby'
import { ChevronLeft, ChevronUp, Edit } from 'react-feather'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon
} from 'react-share'
import LogoR from '../components/LogoR'
import Content from '../components/Content'
import Layout from '../components/Layout'
import '../templates/SinglePost.css'
import Image from '../components/Image'
import { formatToTimeZone } from 'date-fns-timezone'
import scrollTo from 'gatsby-plugin-smoothscroll'
require('date-utils')

const FORMAT = 'YYYY-MM-DD HH:mm'
const TIME_ZONE_TOKYO = 'Asia/Tokyo'

// import _get from 'lodash/get'

function PreviewPage() {
  //microCMS側に設定するurlはhttps://xxxxxx.com/previewPage/?contentId={CONTENT_ID}&draftKey={DRAFT_KEY}みたいな感じ
  const queryParams = getSearchParams()

  //queryParamsには
  //{contentId:"xxxx",
  //draftKey:"xxxx"}
  //というようなデータが入ります。

  const contentId = queryParams.contentId
  const draftKey = queryParams.draftKey

  var [postData, setPostData] = useState(null) //最初、postDataにはnullが入ります。

  useEffect(() => {
    if (!postData) {
      fetch(
        `https://crossmissionblog.microcms.io/api/v1/posts/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': 'VtExZvGJVlsMqJp6QtgzVnBjlwdrfPC53F6l'
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((json) => {
          postData = setPostData(json) //ここで、postDataに取得したコンテンツが格納されます。
        })
    } else {
      return function cleanup() {
        console.log('done')
      }
    }
  }, [postData])

  const PreviewPageTemplate = ({
    updatedAt,
    createdAt,
    category,
    title,
    body,
    url,
    excerpt,
    applySiteURL,
    sponsorLogo,
    toc_visible
  }) => (
    <main>
      <article
        className="SinglePost section light"
        itemScope
        itemType="http://schema.org/BlogPosting"
      >
        <div className="container skinny">
          <Link className="SinglePost--BackButton" to="/post-categories/index">
            <ChevronLeft /> BACK
          </Link>
          <div className="SinglePost--Content relative">
            <div className="SinglePost--Meta">
              {updatedAt && (
                <time
                  className="SinglePost--Meta--Date"
                  itemProp="dateCreated pubdate datePublished"
                  date={updatedAt}
                >
                  {updatedAt}
                </time>
              )}
              {updatedAt === createdAt ? '作成' : '更新'}
              {category && (
                <Fragment>
                  <span>|</span>
                  {category.map((cat, index) => (
                    <span key={cat} className="SinglePost--Meta--Category">
                      {cat}
                      {/* Add a comma on all but last category */}
                      {index !== category.length - 1 ? ',' : ''}
                    </span>
                  ))}
                </Fragment>
              )}
            </div>

            {title && (
              <h1 className="SinglePost--Title" itemProp="title">
                {title}
              </h1>
            )}

            <div className="SinglePost--InnerContent">
              <Content source={body} toc={toc_visible} />
              <ul className="SinglePost--logosContainer">
                {sponsorLogo.length !== 0 &&
                  sponsorLogo.map((logo, index) => (
                    <li
                      className={`${
                        logo.title !== '株式会社アイ・ケイ・アイ' &&
                        logo.title !== 'Beta motorcycles' &&
                        logo.title !== 'フレアライン'
                          ? 'logo-wide'
                          : ''
                      }`}
                    >
                      <Link to={logo.url}>
                        <Image
                          resolutions="small"
                          src={logo.image.url}
                          alt={logo.alt}
                          title={logo.title}
                          className="SinglePost--sponsorLogo"
                        />
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="SinglePost--Button">
              <FacebookShareButton url={url} quote={excerpt}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton title={title} via="ren_x_mission" url={url}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <LineShareButton url={url} quote={excerpt}>
                <LineIcon round size={32} round />
              </LineShareButton>
            </div>
            <div className="SinglePost--Button" style={{ display: "flex" }}>
              <LogoR />
              <p>公式LINEアカウントは<a href="https://lin.ee/3qe595b">こちら</a></p>
            </div>
          </div>
          
        </div>
        {applySiteURL && (
          <div class="button_link">
            <Link to={applySiteURL}>
              <Edit size={40} style={{paddingBottom: '0.5em'}} /><span>申込FORM</span>
            </Link>
          </div>
        )}
        <div class="button_up">
          <p onClick={() => scrollTo('body')}><ChevronUp size={40} />PAGE TOP</p>
        </div>
      </article>
    </main>
  )
  if (postData) {
    return (
      <Layout meta={postData.meta || false} title={postData.title || false}>
        <PreviewPageTemplate
          updatedAt={formatToTimeZone(new Date(postData.updatedAt), FORMAT, {
            timeZone: TIME_ZONE_TOKYO
          })}
          createdAt={formatToTimeZone(new Date(postData.createdAt), FORMAT, {
            timeZone: TIME_ZONE_TOKYO
          })}
          category={postData.category}
          title={postData.title}
          body={postData.content}
          url={typeof window !== 'undefined' ? window.location.href : ''}
          excerpt={postData.excerpt}
          applySiteURL={postData.applySiteURL}
          sponsorLogo={postData.sponsorLogo}
          toc_visible={postData.toc_visible}
        />
      </Layout>
    )
  } else {
    return 'postDataにデータが入っていません'
  }
}

export default PreviewPage
