import React from 'react'
import { graphql, Link } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'
import BackgroundVideo from '../components/BackgroundVideo'
import SponsorLogo from '../components/SponsorLogo'

import Slider from 'react-slick'
import '../components/slick.css'
import '../components/slick-theme.css'
import PostCard from '../components/PostCard'
import '../components/PostSection.css'
import Image from '../components/Image'

export const Carousel = (children) => {
  const settings = {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 2000,
    infinite: children.length > 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          centerMode: true,
          centerPadding: '20px'
        }
      }
    ]
  }
  return <Slider {...settings}>{children}</Slider>
}

export const postEntry = (posts, exceptCondition) =>
  posts &&
  !!posts.length &&
  byDate(posts).filter(
    (post, index) => !post.eventStatus.includes(exceptCondition)
  )

export const byDate = (posts) => {
  const now = Date.now()
  return posts.filter((post) => Date.parse(post.updatedAt) <= now)
}

export const makeBtn = (cat, categorylist) => {
  const target = categorylist.find(({ title }) => cat.includes(title))
  return (
    <Link to={target !== undefined ? target.slug : '/post-categories/その他'}>
      <button
        className="Button-small"
        style={{
          backgroundColor: target !== undefined ? target.color : '#555'
        }}
      >
        {target !== undefined ? target.title : 'その他'}
      </button>
    </Link>
  )
}

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  YouTube1,
  siteUrl1,
  YouTube2,
  siteUrl2,
  body,
  categorylist,
  videoSponsorLogo,
  videoLogo,
  posts = [],
  banner
}) => (
  <main className="Home">
    <section className="BackgroundVideo-section section">
      <BackgroundVideo
        videoSponsorLogo={videoSponsorLogo}
        loadingImage={videoLogo}
      />
      
    </section>
    <SponsorLogo sponsorLogo={videoSponsorLogo.filter((logo) => logo.title !== "ゴールドウィンモーターサイクル" && logo.title !== "株式会社C4PLANNING" && logo.title !== "株式会社スターズトレーディング")} />
    <section className="event-list-section" style={{ paddingBottom: "5em" }}>
      <div
        className="container event-list-container"
        style={{
          backgroundColor: 'white',
          paddingBottom: '4rem',
          maxWidth: '100%'
        }}
      >
        <div className="flex-box">
          <div className="flex-box-item">
            <h2 style={{ margin: '0 0 1rem 3rem' }}>年間カレンダー</h2>

            <table className="Calender">
              <tr>
                <th style={{ textAlign: 'center', width: '18%' }}>
                  Day
                  <br />
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'black',
                      color: 'white',
                      padding: '0 0.4em',
                      width: '100%'
                    }}
                  >
                    日付
                  </span>
                </th>
                <th style={{ textAlign: 'center', width: '59%' }}>
                  Event
                  <br />
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'black',
                      color: 'white',
                      padding: '0 0.4em',
                      width: '100%'
                    }}
                  >
                    イベントタイトル
                  </span>
                </th>
                <th style={{ textAlign: 'center', width: '23%' }}>
                  Category
                  <br />
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'black',
                      color: 'white',
                      width: '100%'
                    }}
                  >
                    カテゴリ
                  </span>
                </th>
              </tr>

              {postEntry(postEntry(posts, '開催終了'), 'イベント以外')
                .sort((a, b) =>
                  Date.parse(
                    b.eventOverview.eventDate.match(
                      /(\d{4}\/\d{1,2}\/\d{1,2})/
                    )[0]
                  ) <
                  Date.parse(
                    a.eventOverview.eventDate.match(
                      /(\d{4}\/\d{1,2}\/\d{1,2})/
                    )[0]
                  )
                    ? 1
                    : -1
                )
                .map((post, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                        width: '18%',
                        fontSize: '0.8em'
                      }}
                    >
                      {post.eventOverview.eventDate}
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        width: '59%',
                        fontSize: '0.8em'
                      }}
                    >
                      <a href={post.slug} style={{ color: '#333' }}>
                        {post.title}
                      </a>
                    </td>
                    <td style={{ textAlign: 'center', width: '23%' }}>
                      {post.category &&
                        post.category.map((cat) => makeBtn(cat, categorylist))}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
          <div className="flex-box-item">
            <h2 style={{ margin: '0 0 1rem 3rem' }}>Information</h2>
            {Carousel(
              postEntry(posts, '開催終了').map((post, index) => (
                <PostCard key={index} {...post} />
              ))
            )}
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2>Category</h2>
        <div className="Category--Container pc">
          {categorylist.map((category, index) => (
            <div
              className="Category--Inner"
              style={{ backgroundImage: `url(${category.image.url})` }}
            >
              <Link to={category.slug} className="Category--Link">
                <svg
                  width="80"
                  height="80"
                  viewBox="-250 0 250 250"
                  aria-hidden="true"
                  fill={category.color}
                  fill-opacity="0.8"
                >
                  <g transform="scale(-1,1)">
                    <path d="M0,0 L115,115 L250,250 L250,0 Z" />
                  </g>
                </svg>
                <p className="Category--Name">{category.title}</p>
              </Link>
            </div>
          ))}
        </div>
        <div className="Grid--Container sp">
          {categorylist.map((category, index) => (
            <div
              className="Category--Inner"
              style={{ backgroundImage: `url(${category.image.url})` }}
            >
              <Link to={category.slug} className="Category--Link">
                <svg
                  width="80"
                  height="80"
                  viewBox="-250 0 250 250"
                  aria-hidden="true"
                  fill={category.color}
                  fill-opacity="0.8"
                >
                  <g transform="scale(-1,1)">
                    <path d="M0,0 L115,115 L250,250 L250,0 Z" />
                  </g>
                </svg>
                <p className="Category--Name">{category.title}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="section-upper">
      <div className="container">
        <Content source={body} />
      </div>
    </section>

    <section className="section-upper">
      <div className="container">
        <h2>Gallery</h2>
        <div className="Grid--Container">
          {categorylist.map((category, index) => (
            <div className="item relative">
              <Link to={`${category.title}Gallery`}>
                <Image
                  resolutions="small"
                  className="Content--Image"
                  src={category.image.url}
                  alt={category.title}
                  title={category.title}
                />
                <p className="Category--Name">{category.title}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="section-upper">
      <div className="container">
        <h2>YouTube</h2>
        <div className="YouTube--Container">
          <div className="YouTube--Content">
            <h3 style={{ textAlign: 'center' }}>ライディングイベント</h3>
            <Content source={YouTube1} className="Content--Iframe" />
            <Link
              to={siteUrl1}
              style={{
                display: 'block',
                color: 'white',
                textDecoration: 'none',
                textAlign: 'center',
                marginTop: '1em'
              }}
            >
              <button class="Button">お申し込みフォームへ</button>
            </Link>
          </div>
          <div className="YouTube--Content">
            <h3 style={{ textAlign: 'center' }}>スクールイベント</h3>
            <Content source={YouTube2} className="Content--Iframe" />
            <Link
              to={siteUrl2}
              style={{
                display: 'block',
                color: 'white',
                textDecoration: 'none',
                textAlign: 'center',
                marginTop: '1em'
              }}
            >
              <button class="Button">お申し込みフォームへ</button>
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className="section-upper">
      <div className="container">
        {!!banner &&
          banner.map((item, index) => (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                marginBottom: '3em'
              }}
            >
              <Image
                resolutions="small"
                className="Content--Image"
                src={item.image.url}
                alt={item.alt}
                title={item.title}
              />
            </a>
          ))}
      </div>
    </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page, posts } }) => (
  <Layout meta={page.meta || false} title={page.meta.title || false}>
    <HomePageTemplate
      {...page}
      body={page.body}
      posts={posts.edges.map((post) => ({
        ...post.node,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: microcmsHomePage(id: { eq: $id }) {
      template
      body
      YouTube1
      siteUrl1
      YouTube2
      siteUrl2
      videoSponsorLogo {
        alt
        image {
          url
        }
        title
        url
      }
      videoLogo {
        url
      }
      categorylist {
        alt
        image {
          url
        }
        title
        slug
        color
      }
      banner {
        alt
        image {
          url
        }
        title
        url
      }
      meta {
        title
        description
      }
    }

    posts: allMicrocmsPosts(sort: { order: DESC, fields: [updatedAt] }) {
      edges {
        node {
          content
          excerpt
          title
          updatedAt
          category
          fields {
            slug
          }
          featuredImage {
            url
          }
          eventStatus
          excerpt
          eventOverview {
            eventDate
            applicationPeriod
            capacity
            place
            price
          }
        }
      }
    }
  }
`
