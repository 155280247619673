import React from 'react'
import { Link } from 'gatsby'

import BlogSearch from './BlogSearch'
import './PostCategoriesNav.css'

const putOrder = (categories) => {
  categories.forEach(function (item) {
    if (item.title.includes('ハード')) {
      item['order'] = 1
    } else if (item.title.includes('オフ')) {
      item['order'] = 2
    } else if (item.title.includes('アドベンチャー')) {
      item['order'] = 3
    } else if (item.title.includes('スクール')) {
      item['order'] = 4
    } else {
      item['order'] = 5
    }
  })
}

const PostCategoriesNav = ({ categories, enableSearch }) => (
  <div className="PostCategoriesNav">
    <Link className="NavLink" exact="true" to={`/post-categories/index`}>
      All
    </Link>
    {putOrder(categories)}
    {categories
      .sort(function (a, b) {
        if (a.order > b.order) {
          return 1
        } else {
          return -1
        }
      })
      .filter((item) => !item.title.includes('Index'))
      .map((category, index) => (
        <Link
          exact="true"
          className="NavLink"
          key={category.title + index}
          to={category.slug.slice(0, category.slug.length - 1)}
        >
          {category.title}
        </Link>
      ))}

    {enableSearch && <BlogSearch />}
  </div>
)

export default PostCategoriesNav
