import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import SVGIcon from '../components/SVGIcon'

// Export Template for use in CMS preview
export const DefaultPageTemplate = ({ title, featuredImage, body }) => (
  <main className="DefaultPage">
    <PageHeader title={title} backgroundImage={featuredImage.url} />

    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>
  </main>
)

const DefaultPage = ({ data: { page } }) => (
  <Layout meta={page.meta || false} title={page.meta.title || false}>
    <DefaultPageTemplate {...page} body={page.body} />
  </Layout>
)
export default DefaultPage

export const pageQuery = graphql`
  query DefaultPage($id: String!) {
    page: microcmsInfoPages(id: { eq: $id }) {
      meta {
        title
        description
      }
      body
      title
      featuredImage {
        url
      }
    }
  }
`
