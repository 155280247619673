import React from 'react'
import { navigate } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'
import '../components/BlogSearch.css'

export default ({ pageCount }) => {
  
  return (
    <Location>
      {({ location }) => {
        let search = qs.parse(location.search.replace('?', ''))

        return (
          <form name="form1" id="search" class="search_blog" action="">
            <input
              type="text"
              className="search_box"
              id="id_search"
              name="search"
              defaultValue={search.s || ''}
              placeholder="Search..."
              onChange={e => {
                // let search = {}
                // search.s = e.target.value
                // search = qs.stringify(search)

                // const url = location.href
                //   .replace(location.origin, '')
                //   .replace(location.search, '')

                // navigate(`${url}?${search}`)
              }}
            />
            <input
              type="button"
              value="検索"
              onClick={e => {
                let search = {}
                search.s = document.form1.id_search.value
                search = qs.stringify(search)

                const url = location.href
                  .replace(location.origin, '')
                  .replace(location.search, '')

                navigate(`${url}?${search}`)
              }}
            />
          </form>
        )
      }}
    </Location>
  )
}
