import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'

import PageHeader from '../components/PageHeader'
import GallerySection from '../components/GallerySection'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
export const GalleryPageTemplate = ({
  title,
  subTitle,
  featuredImage,
  galleryImages = []
}) => (
  <Location>
    {() => {
      return (
        <main className="Blog">
          <PageHeader
            title={title}
            subtitle={subTitle}
            backgroundImage={featuredImage.url}
          />
          {!!galleryImages.length && (
            <section className="section">
              <div className="container">
                <GallerySection images={galleryImages} />
              </div>
            </section>
          )}
        </main>
      )
    }}
  </Location>
)

// Export Default GalleryPage for front-end
const GalleryPage = ({ data: { page } }) => (
  <Layout meta={page.meta || false} title={page.title || false}>
    <GalleryPageTemplate {...page} {...page.galleryImages} />
  </Layout>
)

export default GalleryPage

export const pageQuery = graphql`
  ## Query for GalleryPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query GalleryPage($id: String!) {
    page: microcmsGalleryPages(id: { eq: $id }) {
      title
      subTitle
      featuredImage {
        url
      }
      galleryImages {
        title
        image {
          url
        }
      }
      meta {
        title
        description
      }
    }
  }
`
