import React, { Component } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'
import Logo from './Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faYoutube,
  faLine
} from '@fortawesome/free-brands-svg-icons'
import './Nav.css'

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }

  componentDidMount = () =>
    this.setState({ currentPath: this.props.location.pathname })

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = (subNav) =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active } = this.state,
      { subNav } = this.props,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
        <div className="Nav--Container container">
          <Link to="/" onClick={this.handleLinkClick}>
            <Logo />
          </Link>
          <div className="Nav--Links">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/biography/">Biography</NavLink>
            <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'posts' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories')
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('posts')}
              >
                Category
                <div className="Nav--GroupLinks">
                  <NavLink
                    to="/post-categories/index"
                    className="Nav--GroupLink"
                  >
                    All
                  </NavLink>
                  {subNav.posts.forEach(function (item) {
                    if (item.title.includes('ハード')) {
                      item['order'] = 1
                    } else if (item.title.includes('オフ')) {
                      item['order'] = 2
                    } else if (item.title.includes('アドベンチャー')) {
                      item['order'] = 3
                    } else if (item.title.includes('スクール')) {
                      item['order'] = 4
                    } else {
                      item['order'] = 5
                    }
                  })}
                  {subNav.posts
                    .sort(function (a, b) {
                      if (a.order > b.order) {
                        return 1
                      } else {
                        return -1
                      }
                    })
                    .filter((item) => !item.title.includes('Index'))
                    .map((link, index) => (
                      <NavLink
                        to={link.slug.slice(0, link.slug.length - 1)}
                        key={'posts-subnav-link-' + index}
                        className="Nav--GroupLink"
                      >
                        {link.title}
                      </NavLink>
                    ))}
                </div>
              </span>
            </div>
            {/* <NavLink to="/default/">Default</NavLink> */}
            <NavLink to="/contact/">Contact</NavLink>
            <NavLink to="/hoken/">CROSS MISSION ID</NavLink>
            <NavLink to="/post-categories/全日本ハードエンデューロ選手権">全日本ハードエンデューロ選手権</NavLink>
            <NavLink to="/post-categories/cross-mission-アドベンチャーラリー">アドベンチャーラリー</NavLink>
            <div class="Nav--SNS sp">
              <a
                href={`https://ja-jp.facebook.com/ishidoya.ren`}
                style={{ boxShadow: `none` }}
              >
                <FontAwesomeIcon
                  color="#3b5998"
                  icon={faFacebookSquare}
                  style={{
                    width: `1.5em`,
                    height: `1.5em`,
                    marginRight: `1.5em`
                  }}
                />
              </a>
              <a
                href={`https://twitter.com/ren_x_mission`}
                style={{ boxShadow: `none` }}
              >
                <FontAwesomeIcon
                  color="#3eaded"
                  icon={faTwitterSquare}
                  style={{
                    width: `1.5em`,
                    height: `1.5em`,
                    marginRight: `1.5em`
                  }}
                />
              </a>
              <a
                href={`https://www.instagram.com/cross.mission/`}
                style={{ boxShadow: `none` }}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  color="#000"
                  style={{
                    width: `1.5em`,
                    height: `1.5em`,
                    marginRight: `1.5em`
                  }}
                />
              </a>
              <a
                href={`https://www.instagram.com/ishidoya_ren/`}
                style={{ boxShadow: `none` }}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  color="#3AE09A"
                  style={{
                    width: `1.5em`,
                    height: `1.5em`,
                    marginRight: `1.5em`
                  }}
                />
              </a>
              <a
                href={`https://www.youtube.com/channel/UCpXVNW-941B6Ewpfu9AkR_w`}
                style={{ boxShadow: `none` }}
              >
                <FontAwesomeIcon
                  color="#FF0000"
                  icon={faYoutube}
                  style={{
                    width: `1.875em`,
                    height: `1.5em`,
                    marginRight: `1.5em`
                  }}
                />
              </a>
              <a href={`https://lin.ee/3qe595b`} style={{ boxShadow: `none` }}>
                <FontAwesomeIcon
                  color="#00B900"
                  icon={faLine}
                  style={{
                    width: `1.5em`,
                    height: `1.5em`
                  }}
                />
              </a>
            </div>
          </div>
          <div class="Nav--SNS pc">
            <a
              href={`https://ja-jp.facebook.com/ishidoya.ren`}
              style={{ boxShadow: `none` }}
            >
              <FontAwesomeIcon
                color="#3b5998"
                icon={faFacebookSquare}
                style={{
                  width: `1.2em`,
                  height: `1.2em`,
                  marginRight: `1.5em`
                }}
              />
            </a>
            <a
              href={`https://twitter.com/ren_x_mission`}
              style={{ boxShadow: `none` }}
            >
              <FontAwesomeIcon
                color="#3eaded"
                icon={faTwitterSquare}
                style={{
                  width: `1.2em`,
                  height: `1.2em`,
                  marginRight: `1.5em`
                }}
              />
            </a>
            <a
              href={`https://www.instagram.com/cross.mission/`}
              style={{ boxShadow: `none` }}
            >
              <FontAwesomeIcon
                icon={faInstagram}
                color="#000"
                style={{
                  width: `1.2em`,
                  height: `1.2em`,
                  marginRight: `1.5em`
                }}
              />
            </a>
            <a
              href={`https://www.instagram.com/ishidoya_ren/`}
              style={{ boxShadow: `none` }}
            >
              <FontAwesomeIcon
                icon={faInstagram}
                color="#3AE09A"
                style={{
                  width: `1.2em`,
                  height: `1.2em`,
                  marginRight: `1.5em`
                }}
              />
            </a>
            <a
              href={`https://www.youtube.com/channel/UCpXVNW-941B6Ewpfu9AkR_w`}
              style={{ boxShadow: `none` }}
            >
              <FontAwesomeIcon
                color="#FF0000"
                icon={faYoutube}
                style={{
                  width: `1.5em`,
                  height: `1.2em`,
                  marginRight: `1.5em`
                }}
              />
            </a>
            <a href={`https://lin.ee/3qe595b`} style={{ boxShadow: `none` }}>
              <FontAwesomeIcon
                color="#00B900"
                icon={faLine}
                style={{
                  width: `1.2em`,
                  height: `1.2em`,
                  marginRight: `1.5em`
                }}
              />
            </a>
          </div>
          <button
            className="Button-blank Nav--MenuButton"
            onClick={this.handleMenuToggle}
          >
            {active ? <X /> : <Menu />}
          </button>
        </div>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{(route) => <Navigation subNav={subNav} {...route} />}</Location>
)
