import React from 'react'
import './LogoR.css'

export default () => (
  <div
    className="LogoR"
    style={{
      backgroundImage: `url(/images/logoR.jpg)`
    }}
  />
)
