import React from 'react'
import { Link } from 'gatsby'

import { Background } from 'react-imgix'
import './PostCard.css'

import { formatToTimeZone } from 'date-fns-timezone'

const FORMAT = 'YYYY-MM-DD HH:mm'
const TIME_ZONE_TOKYO = 'Asia/Tokyo'

const PostCard = ({
  updatedAt,
  createdAt,
  featuredImage,
  title,
  excerpt,
  eventOverview,
  eventStatus,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`PostCard ${className}`}>
    {featuredImage && (
      <div className="PostCard--Image relative">
        <Background
          src={featuredImage.url}
          htmlAttributes={{ alt: { title } }}
        />
      </div>
    )}
    <div className="PostCard--Content">
      {title && <h3 className="PostCard--Title">{title}</h3>}
      <div className="PostCard--Category">
        {categories && categories.map((cat) => cat.category).join(', ')}
      </div>
      {updatedAt && eventStatus && !eventStatus.includes('イベント以外') && (
        <div className="PostCard--eventStatus">
          {formatToTimeZone(new Date(updatedAt), FORMAT, {
            timeZone: TIME_ZONE_TOKYO
          })}
          {updatedAt === createdAt ? '作成' : '更新'}
        </div>
      )}
      {eventStatus && !eventStatus.includes('イベント以外') && (
        <div className="PostCard--eventStatus">
          {eventStatus}
          {!eventStatus.includes('イベント以外') &&
            !eventStatus.includes('開催終了') &&
            '(上記日時での状況です。変更の可能性があります。)'}
        </div>
      )}

      <div className="PostCard--eventDetail">
        {eventOverview && (
          <div className="PostCard--eventDetailElement">
            開催日:{eventOverview.eventDate}
          </div>
        )}
        {eventOverview && (
          <div className="PostCard--eventDetailElement">
            募集期間:{eventOverview.applicationPeriod}
          </div>
        )}
        {eventOverview && (
          <div className="PostCard--eventDetailElement">
            定員:{eventOverview.capacity}
          </div>
        )}
        {eventOverview && (
          <div className="PostCard--eventDetailElement">
            場所:{eventOverview.place}
          </div>
        )}
        {eventOverview && (
          <div className="PostCard--eventDetailElement">
            料金:{eventOverview.price}
          </div>
        )}
      </div>
      {excerpt && <div className="PostCard--Excerpt">{excerpt}</div>}
    </div>
  </Link>
)

export default PostCard
