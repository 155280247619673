import React, { useState, useEffect, Fragment } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { getSearchParams } from 'gatsby-query-params'
import Content from '../components/Content'
import Layout from '../components/Layout'
import BackgroundVideo from '../components/BackgroundVideo'
import SponsorLogo from '../components/SponsorLogo'

import Slider from 'react-slick'
import '../components/slick.css'
import '../components/slick-theme.css'
import PostCard from '../components/PostCard'
import '../components/PostSection.css'
import Image from '../components/Image'

// import _get from 'lodash/get'

export const Carousel = ( children ) => {
  const settings = {
    dots: true,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    speed: 2000,
    infinite: children.length > 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 2000,
          centerMode: true,
          centerPadding: '20px'
        }
      }
    ]
  };
  return <Slider {...settings}>{children}</Slider>;
}

export const postEntry = (posts, exceptCondition) => (
  posts &&
    !!posts.length &&
    byDate(posts)
      .filter((post, index) => !post.eventStatus.includes(exceptCondition))
)

export const byDate = (posts) => {
  const now = Date.now()
  return posts.filter((post) => Date.parse(post.updatedAt) <= now)
}

export const makeBtn = (cat, categorylist) => {
  const target = categorylist.find(({ title }) => cat.includes(title))
  return (
    <Link to={target !== undefined ? target.slug : "/post-categories/その他"}>
      <button className="Button-small" style={{ backgroundColor: target !== undefined ? target.color : "#555" }}>
        {target !== undefined ? target.title : "その他"}
      </button>
    </Link>
  )
}

function PreviewHome() {
  //microCMS側に設定するurlはhttps://xxxxxx.com/previewHome/?contentId={CONTENT_ID}&draftKey={DRAFT_KEY}みたいな感じ
  const queryParams = getSearchParams()

  //queryParamsには
  //{contentId:"xxxx",
  //draftKey:"xxxx"}
  //というようなデータが入ります。

  const contentId = queryParams.contentId
  const draftKey = queryParams.draftKey

  var [pageData, setpageData] = useState(null) //最初、pageDataにはnullが入ります。

  useEffect(() => {
    if (!pageData) {
      fetch(
        `https://crossmissionblog.microcms.io/api/v1/home_page/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': 'VtExZvGJVlsMqJp6QtgzVnBjlwdrfPC53F6l'
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((json) => {
          pageData = setpageData(json) //ここで、pageDataに取得したコンテンツが格納されます。
        })
    } else {
      return function cleanup() {
        console.log('done')
      }
    }
  }, [pageData])

  const data = useStaticQuery(graphql`
    query {
      posts: allMicrocmsPosts(sort: { order: DESC, fields: [updatedAt] }) {
        edges {
          node {
            content
            excerpt
            title
            updatedAt
            category
            fields {
              slug
            }
            featuredImage {
              url
            }
            eventStatus
            excerpt
            eventOverview {
              eventDate
              applicationPeriod
              capacity
              place
              price
            }
          }
        }
      }
    }
  `)

  const PreviewPageTemplate = ({
    YouTube1,
    siteUrl1,
    YouTube2,
    siteUrl2,
    body,
    categorylist,
    videoSponsorLogo,
    videoLogo,
    posts = [],
    banner
  }) => (
    <main className="Home">
      <section className="BackgroundVideo-section section">
        <BackgroundVideo
          videoSponsorLogo={videoSponsorLogo}
          loadingImage={videoLogo}
        />
      </section>
      <SponsorLogo sponsorLogo={videoSponsorLogo.filter((logo) => logo.title !== "ゴールドウィンモーターサイクル" && logo.title !== "バイクマン" && logo.title !== "株式会社ムサシ" && logo.title !== "株式会社アイ・ケイ・アイ")} />
      <section className="event-list-section" style={{ paddingBottom: "5em" }}>
      <div className="container event-list-container"
        style={{ backgroundColor: 'white', paddingBottom: '4rem', maxWidth: "100%" }}>
        <div className="flex-box">
          <div className="flex-box-item">
            <h2 style={{ margin: '0 0 1rem 3rem' }}>年間カレンダー</h2>

            <table style={{ width: "100%", tableLayout: "fixed", borderCollapse: "separate" }}>
              <tr>
                <th style={{ textAlign: "center",  width: "18%" }}>Day<br /><span style={{ display: "inline-block", backgroundColor: "black", color: "white", padding: "0 0.4em", width: "100%" }}>日付</span></th>
                <th style={{ textAlign: "center",  width: "59%" }}>Event<br/><span style={{ display: "inline-block", backgroundColor: "black", color: "white", padding: "0 0.4em", width: "100%" }}>イベントタイトル</span></th>
                <th style={{ textAlign: "center",  width: "23%" }}>Category<br/><span className="pc-inline" style={{ backgroundColor: "black", color: "white",width: "100%" }}>カテゴリ名</span><span className="sp-inline" style={{ backgroundColor: "black", color: "white",width: "100%" }}>カテゴリ</span></th>
              </tr>
              
              {postEntry(postEntry(posts, '開催終了'), 'イベント以外').sort((a, b) => (Date.parse(b.eventOverview.eventDate) < Date.parse(a.eventOverview.eventDate) ? 1 : -1)).map((post, index) =>  
                <tr>
                  <td style={{ textAlign: "center", width: "18%", fontSize: "0.8em" }}>{post.eventOverview.eventDate}</td>
                  <td style={{ textAlign: "center", width: "59%", fontSize: "0.8em" }}><a href={post.slug} style={{  color: "#333" }}>{post.title}</a></td>
                  <td style={{ textAlign: "center", width: "23%" }}>
                    {post.category && post.category.map((cat) =>
                      makeBtn(cat, categorylist)
                    )}
                  </td>
                </tr>
              )}
            </table>
          </div>
          <div className="flex-box-item">
            <h2 style={{ margin: '0 0 1rem 3rem' }}>Information</h2>  
            {Carousel(postEntry(posts, '開催終了').map((post, index) => <PostCard key={index} {...post} />))}
          </div>
        </div>
      </div>
    </section>

      <section className="section">
        <div className="container">
          <h2>Category</h2>
          <div className="Category--Container pc">
            {categorylist.map((category, index) => (
              <div
                className="Category--Inner"
                style={{ backgroundImage: `url(${category.image.url})` }}
              >
                <Link to={category.slug} className="Category--Link">
                  <svg
                    width="80"
                    height="80"
                    viewBox="-250 0 250 250"
                    aria-hidden="true"
                    fill={category.color}
                    fill-opacity="0.8"
                  >
                    <g transform="scale(-1,1)">
                      <path d="M0,0 L115,115 L250,250 L250,0 Z" />
                    </g>
                  </svg>
                  <p className="Category--Name">{category.title}</p>
                </Link>
              </div>
            ))}
          </div>
          <div className="Grid--Container sp">
            {categorylist.map((category, index) => (
              <div
                className="Category--Inner"
                style={{ backgroundImage: `url(${category.image.url})` }}
              >
                <Link to={category.slug} className="Category--Link">
                  <svg
                    width="80"
                    height="80"
                    viewBox="-250 0 250 250"
                    aria-hidden="true"
                    fill={category.color}
                    fill-opacity="0.8"
                  >
                    <g transform="scale(-1,1)">
                      <path d="M0,0 L115,115 L250,250 L250,0 Z" />
                    </g>
                  </svg>
                  <p className="Category--Name">{category.title}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="section-upper">
        <div className="container">
          <Content source={body} />
        </div>
      </section>

      <section className="section-upper">
        <div className="container">
          <h2>Gallery</h2>
          <div className="Grid--Container">
            {categorylist.map((category, index) => (
              <div className="item relative">
                <Link to={`${category.title}Gallery`}>
                  <Image
                    resolutions="small"
                    className="Content--Image"
                    src={category.image.url}
                    alt={category.title}
                    title={category.title}
                  />
                  <p className="Category--Name">{category.title}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="section-upper">
        <div className="container">
          <h2>YouTube</h2>
          <div className="YouTube--Container">
            <div className="YouTube--Content">
              <h3 style={{ textAlign: 'center' }}>ライディングイベント</h3>
              <Content source={YouTube1} className="Content--Iframe" />
              <Link
                to={siteUrl1}
                style={{
                  display: 'block',
                  color: 'white',
                  textDecoration: 'none',
                  textAlign: 'center',
                  marginTop: '1em'
                }}
              >
                <button class="Button">お申し込みフォームへ</button>
              </Link>
            </div>
            <div className="YouTube--Content">
              <h3 style={{ textAlign: 'center' }}>スクールイベント</h3>
              <Content source={YouTube2} className="Content--Iframe" />
              <Link
                to={siteUrl2}
                style={{
                  display: 'block',
                  color: 'white',
                  textDecoration: 'none',
                  textAlign: 'center',
                  marginTop: '1em'
                }}
              >
                <button class="Button">お申し込みフォームへ</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="section-upper">
        <div className="container">
          {!!banner &&
            banner.map((item, index) => (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'block',
                  marginBottom: '3em'
                }}
              >
                <Image
                  resolutions="small"
                  className="Content--Image"
                  src={item.image.url}
                  alt={item.alt}
                  title={item.title}
                />
              </a>
            ))}
        </div>
      </section>
    </main>
  )
  if (pageData) {
    return (
      <Layout meta={pageData.meta || false} title={pageData.title || false}>
        <PreviewPageTemplate
          YouTube1={pageData.YouTube1}
          siteUrl1={pageData.siteUrl1}
          YouTube2={pageData.YouTube2}
          siteUrl2={pageData.siteUrl2}
          categorylist={pageData.categorylist.map((cat) => ({
            ...cat,
            ...cat.image
          }))}
          videoSponsorLogo={pageData.videoSponsorLogo.map((logo) => ({
            ...logo,
            ...logo.image
          }))}
          videoLogo={pageData.videoLogo}
          banner={pageData.banner.map((ban) => ({
            ...ban,
            ...ban.image
          }))}
          body={pageData.body}
          posts={data.posts.edges.map((post) => ({
            ...post.node,
            ...post.node.fields
          }))}
        />
      </Layout>
    )
  } else {
    return 'pageDataにデータが入っていません'
  }
}

export default PreviewHome
